import { createContext, ReactNode, SyntheticEvent, useContext, useEffect, useRef } from 'react';
import { useHookstate } from '@hookstate/core';
import { computePosition, flip, offset, size } from '@floating-ui/react-dom-interactions';
import DropDownIcon from '~/components/icons/DropDownIcon';
import classNames from 'classnames';
import {
  FORM_CLASS_FOCUS,
  FORM_CLASS_INPUT_BG_DISABLED,
  FORM_CLASS_INPUT_BG_ENABLED,
  FORM_CLASS_INPUT_TEXT_DISABLED,
  FORM_CLASS_INPUT_TEXT_ENABLED,
} from '~/components/input/common';
import { Portal } from '~/components/interactive/Portals';

const Context = createContext<(() => void) | null>(null);

export function useCloseDropButton() {
  const close = useContext(Context);

  if (!close) {
    throw new Error('Cannot call useCloseDropButton() outside of <DropButton />');
  }

  return close;
}

export interface DropButtonProps {
  icon: ReactNode;
  children: ReactNode;
  text?: string;
  width?: boolean;
}

export default function DropButton({ icon, children, text, width }: DropButtonProps) {
  const state = useHookstate(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const popup = useRef<HTMLDivElement>();
  const hover = state.get()
    ? ''
    : 'hover:ring-1 hover:ring-theme-highlight hover:border-white cursor-pointer ring-inset hover:text-black';
  const border = state.get() ? 'bg-white border border-gray-300' : '';
  const style = useRef<Record<string, number>>();

  const calculate = () => {
    if (!popup.current || !ref.current) {
      return;
    }

    const middleware = [flip({ altBoundary: true })];

    if (width) {
      middleware.push(
        size({
          apply({ rects, elements }) {
            if (rects.reference.width > 100) {
              Object.assign(elements.floating.style, {
                width: `${rects.reference.width}px`,
              });
            }
          },
        }),
      );
    }

    middleware.push(offset(-1));

    computePosition(ref.current, popup.current, { middleware, placement: 'bottom-start' })
      .then(({ x, y }) => {
        if (popup.current && ref.current) {
          Object.assign(popup.current.style, {
            top: `${y}px`,
            left: `${x}px`,
          });

          if (x >= 0 && y >= 0) {
            popup.current.style.borderTopLeftRadius = '0';
          }

          if (x <= 0 && y >= 0) {
            popup.current.style.borderTopRightRadius = '0';
          }

          if (x >= 0 && y < 0) {
            popup.current.style.borderBottomLeftRadius = '0';
          }

          if (x <= 0 && y < 0) {
            popup.current.style.borderBottomRightRadius = '0';
          }

          if (y >= 0) {
            style.current = {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomWidth: 0,
            };
          } else {
            style.current = {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderTopWidth: 0,
            };
          }
        }
      })
      .catch((e) => reportError(e));
  };

  const toggle = (event: SyntheticEvent) => {
    event.preventDefault();

    state.set((s) => {
      if (s) {
        style.current = {};
        return false;
      }

      return true;
    });
  };

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const target = event.target as Node | null;

      if (target && !wrapper.current?.contains(target) && !popup.current?.contains(target)) {
        state.set(false);
      }
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  });

  return (
    <Context.Provider
      value={() => {
        state.set(false);
      }}
    >
      <div className="@container w-full group">
        <div
          className={classNames(
            'text-base w-full rounded-md flex items-center focus:outline-none sm:text-sm relative bg-white cursor-pointer justify-center pt-2 @[50px]:p-2 @[50px]:border @[50px]:justify-start',
            {
              'border-gray-300': !state.get(),
              'border-transparent': state.get(),
              [FORM_CLASS_INPUT_BG_DISABLED]: false,
              [FORM_CLASS_INPUT_BG_ENABLED]: true,
              [FORM_CLASS_INPUT_TEXT_DISABLED]: false,
              [FORM_CLASS_INPUT_TEXT_ENABLED]: true,
              [FORM_CLASS_FOCUS]: true,
            },
          )}
          ref={wrapper}
        >
          <div
            className={classNames(
              'z-30 text-base select-none size-4 flex-shrink-0 transition group-hover:scale-110 group-hover:text-gray-600',
              { 'text-lg': !text },
            )}
            onClick={toggle}
          >
            {icon}
          </div>
          {text && (
            <>
              <div
                className="text-sm z-30 font-medium ml-2 mr-1 w-full select-none overflow-hidden whitespace-nowrap hidden @[50px]:block"
                onClick={toggle}
              >
                {text}
              </div>
              <div className="w-3 h-3 z-30 select-none hidden @[50px]:block" onClick={toggle}>
                <DropDownIcon />
              </div>
            </>
          )}
          <div
            ref={ref}
            style={style.current}
            className={`absolute top-0 rounded-md left-0 w-full h-full z-20 ${border}`}
            onClick={toggle}
          />
          <div
            className={`absolute top-0 left-0 rounded-md bg-white border border-gray-300 shadow-lg z-50 overflow-hidden ${
              state.get() ? '' : 'invisible'
            }`}
            ref={(el) => {
              popup.current = el ?? undefined;

              calculate();
            }}
          >
            <div className="w-32 h-0" />
            {children}
          </div>
        </div>
      </div>
    </Context.Provider>
  );
}
